import React from 'react'
// import Image from 'gatsby-image'
import {Link} from 'gatsby'
import PortableText from './portableText'
import Container from './container'

import styles from './page.module.css'

const Page = ({title, _rawBody}) => {
  // console.log({pageSidebar})
  return (
    <>

      <article className={styles.root} >

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div>
            <div className={styles.mainContent}>
              <div style={{paddingRight: '30px', width: '100%'}}>
                {_rawBody && <PortableText blocks={_rawBody} />}
              </div>

            </div>
          </div>
        </Container>
      </article>

    </>
  )
}
export default Page
